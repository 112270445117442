import React, { useState } from "react";
import { Button } from "@asu-design-system/components-core/dist/libCore.es";
import axios from "axios";
import useServiceAuth from "hooks/useServiceAuth";
import useDataTable from "hooks/useDataTable";

const AddForm = () => {
  const { getAccessToken } = useServiceAuth();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [APIData, setAPIData] = useState("");
  const { applyChange } = useDataTable();

  // Send an API request to the AWS Lambda function using API Gateway
  async function requestAPI() {
    const token = await getAccessToken();
    const api = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_ENV}/items`;
    // const api = `https://cfqwyu1v1g.execute-api.us-west-2.amazonaws.com/qa/items`;
    const data = {
      id: id,
      price: Number(price),
      name: name,
    };
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };

    console.log(api);

    // id, name, and price must have a value before trying to make the request
    if (id && name && price) {
      // Send PUT request to API and receive a response based on the id
      axios
        .put(api, data, headers)
        .then((response) => {
          console.log(response.data);
          setAPIData(response.data);
        })
        // Catch any errors that may occur with the API
        .catch((error) => {
          console.log(error);
        });
    } else {
      setAPIData("ID, Name, and Price are required");
    }
  }

  return (
    <div className="container">
      <h2>Add an item</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // Send API request on sumbit
          requestAPI();
          applyChange();
        }}
        className="row row-spaced pt-5 pb-5"
      >
        <label htmlFor="id" className="col-3 px-2">
          <div>
            <text className="pt-1" style={{ fontSize: "14pt" }}>
              ID:&nbsp;
            </text>
            <input
              id="id"
              value={id}
              placeholder="id"
              onChange={(e) => setId(e.target.value)}
              style={{ width: "80%", height: "40px" }}
            />
          </div>
        </label>
        <label htmlFor="name" className="col-5">
          <div>
            <text className="pt-1" style={{ fontSize: "14pt" }}>
              Name:&nbsp;
            </text>
            <input
              className=""
              id="name"
              value={name}
              placeholder="name"
              onChange={(e) => setName(e.target.value)}
              style={{ width: "80%", height: "40px" }}
            />
          </div>
        </label>
        <label htmlFor="price" className="col-3">
          <div>
            <text className="pt-1" style={{ fontSize: "14pt" }}>
              Price:&nbsp;
            </text>
            <input
              id="price"
              type="number"
              min="0"
              value={price}
              placeholder="price"
              onChange={(e) => setPrice(e.target.value)}
              style={{ width: "70%", height: "40px" }}
            />
          </div>
        </label>

        <div className="col-1">
          <Button color="gold" label="Submit" size="small">
            Submit
          </Button>
        </div>
      </form>

      {/* Changes whether a request has been made or not */}
      {APIData.length ? <h3>{APIData}</h3> : <br />}
    </div>
  );
};

export default AddForm;
