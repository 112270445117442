import {
  bufferToBase64UrlEncoded,
  createRandomString,
  encode,
  sha256,
} from "../utils/ServiceAuthUtils";
import axios from "axios";
import qs from "qs";
import { decode } from "jsonwebtoken";

// In this file, any line marked with "CHANGE PER APP" needs to be changed when using ServiceAuth in a new app
// Variables that follow the format "boilerplate.*.*" do not have to be changed but it is a good idea to replace the "boilerplate" in each to better represent the new app

class ServiceAuthClient {
  constructor() {
    this.serviceauthRedirectUri =
      "https://lms-cesys-qa.apps.asu.edu";
    this.serviceauthOauthUrl =
      "https://weblogin.asu.edu/serviceauth/oauth2/native/allow";
    this.serviceauthTokenUrl =
      "https://weblogin.asu.edu/serviceauth/oauth2/native/token";
    this.serviceauthTokenRefreshUrl =
      "https://weblogin.asu.edu/serviceauth/oauth2/token";

    this.scopes = [
      "https://api.myasuplat-dpl.asu.edu/scopes/principal/read:self",
      "https://api.myasuplat-dpl.asu.edu/scopes/person/read:self",
      "https://lms-cesys-qa.apps.asu.edu/authorize:self", // CHANGE PER APP
    ];

    this.serviceauthId = "lms-cesys-nonprod"; // CHANGE PER APP
    this.serviceauthSecret = "serviceauth-public-agent";

    this.SS_SA_CODE_VERIFIER = "boilerplate.serviceauth.codeVerifier";
    this.SS_SA_STATE = "boilerplate.serviceauth.state";
    this.SS_RETURN = "boilerplate.serviceauth.return";
    this.SS_JWT_TOKEN = "boilerplate.jwt.token";
    this.SS_JWT_REFRESH_TOKEN = "boilerplate.jwt.refresh.token";
    this.SS_JWT_EXPIRATION = "boilerplate.jwt.expiration";
    this.TOKEN_EXPIRES_IN_SECONDS = 540;

    // this.DPL_BASE_URL = "https://api.myasuplat-dpl.asu.edu/api";
  }

  async redirectToServiceauth() {
    const url = await this.redirectToServiceauthUrl();
    window.location["assign"](url);
  }

  async redirectToServiceauthUrl(options) {
    const state = encode(createRandomString());
    const codeVerifier = createRandomString();
    const codeChallengeBuffer = await sha256(codeVerifier);
    const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer);

    var scopeParam = "";
    for (var i = 0; i < this.scopes.length; i++) {
      if (scopeParam.length > 0) {
        scopeParam += " ";
      }
      scopeParam += this.scopes[i];
    }

    sessionStorage.setItem(this.SS_SA_CODE_VERIFIER, codeVerifier);
    sessionStorage.setItem(this.SS_SA_STATE, state);
    sessionStorage.setItem(
      this.SS_RETURN,
      `${window.location.pathname}${window.location.search}`
    );

    var url = this.serviceauthOauthUrl;
    url += "?response_type=code";
    url += "&client_id=" + encodeURIComponent(this.serviceauthId);
    url += "&redirect_uri=" + encodeURIComponent(this.serviceauthRedirectUri);
    url += "&state=" + encodeURIComponent(state);
    url += "&code_challenge_method=S256";
    url += "&code_challenge=" + codeChallenge;
    url += "&scope=" + encodeURIComponent(scopeParam);

    console.log("Redirecting to auth server at" + url + "...");

    return url;
  }

  async handleRedirectCallback(url = window.location.href) {
    const queryStringFragments = url.split("?").slice(1);

    if (queryStringFragments.length === 0) {
      throw new Error("There are no query params available for parsing.");
    }

    const { state, code } = qs.parse(queryStringFragments.join(""));

    const storedState = sessionStorage.getItem(this.SS_SA_STATE);
    const codeVerifier = sessionStorage.getItem(this.SS_SA_CODE_VERIFIER);
    let returnTo = sessionStorage.getItem(this.SS_RETURN);
    sessionStorage.removeItem(this.SS_SA_STATE);
    sessionStorage.removeItem(this.SS_SA_CODE_VERIFIER);
    sessionStorage.removeItem(this.SS_RETURN);

    if (state !== storedState) throw new Error("State values do not match.");
    // console.log("code: ", code);
    // console.log("query state: ", state);
    // console.log("session state: ", storedState);
    // console.log("code verifier: ", codeVerifier);

    // ASU version
    const response = await axios.post(
      this.serviceauthTokenUrl,
      qs.stringify({
        grant_type: "authorization_code",
        code: code,
        redirect_uri: this.serviceauthRedirectUri,
        client_id: this.serviceauthId,
        client_secret: this.serviceauthSecret,
        code_verifier: codeVerifier,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    const { data } = response;
    // console.log("response from IdP", response);

    console.log("Saving token to session storage...");

    console.table(decode(data.access_token));
    sessionStorage.setItem(this.SS_JWT_TOKEN, data.access_token);
    sessionStorage.setItem(this.SS_JWT_REFRESH_TOKEN, data.refresh_token);
    var t = new Date(
      new Date().getTime() + this.TOKEN_EXPIRES_IN_SECONDS * 1000
    );
    sessionStorage.setItem(this.SS_JWT_EXPIRATION, t);

    // console.log("Token has been set: " + sessionStorage.getItem(this.SS_JWT_TOKEN));
    // console.log("Refresh token has been set: " + sessionStorage.getItem(this.SS_JWT_REFRESH_TOKEN));

    // If user is logging in from login page, return to redirect URL
    if (returnTo.startsWith("/login")) returnTo = "/";

    return { returnTo };
  }

  async checkSession() {
    console.log("checking session");
    if (!sessionStorage.getItem(this.SS_JWT_TOKEN)) {
      console.log("No JWT was found in session storage");
      return;
    }

    try {
      await this.getAccessToken();
    } catch (error) {
      console.log("Error when checking session: ", error);
    }
  }

  async getAccessToken() {
    console.log("Getting access token...");

    const getRefreshedAccessToken = async () => {
      console.log("Refreshing token for " + this.serviceauthId + "...");

      var refreshToken = sessionStorage.getItem(this.SS_JWT_REFRESH_TOKEN);

      var scopeParam = "";
      for (var i = 0; i < this.scopes.length; i++) {
        if (scopeParam.length > 0) {
          scopeParam += " ";
        }
        scopeParam += this.scopes[i];
      }

      // Request to refresh access token
      const response = await axios.post(
        this.serviceauthTokenRefreshUrl,
        qs.stringify({
          grant_type: "refresh_token",
          refresh_token: refreshToken,
          client_id: this.serviceauthId,
          client_secret: this.serviceauthSecret,
          scope: scopeParam,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { data } = response;
      // console.log("Response from IdP", response);

      console.log("Saving token to session storage...");
      sessionStorage.setItem(this.SS_JWT_TOKEN, data.access_token);

      var t = new Date(
        new Date().getTime() + this.TOKEN_EXPIRES_IN_SECONDS * 1000
      );

      sessionStorage.setItem(this.SS_JWT_EXPIRATION, t);
    };

    try {
      const currentDate = new Date();
      const tokenExpirationDate = new Date(
        sessionStorage.getItem(this.SS_JWT_EXPIRATION)
      );

      console.log("Current date and time: " + currentDate);
      console.log("Token expires: " + tokenExpirationDate);
      if (
        sessionStorage.getItem(this.SS_JWT_TOKEN) !== null &&
        currentDate > tokenExpirationDate
      ) {
        console.log("Need to refresh token");

        // console.log("Old token: " + sessionStorage.getItem(this.SS_JWT_TOKEN));
        await getRefreshedAccessToken();
        // console.log("New token: " + sessionStorage.getItem(this.SS_JWT_TOKEN));
      }
    } catch (e) {
      console.log("Error on refresh: " + e);
    }

    return sessionStorage.getItem(this.SS_JWT_TOKEN);
  }

  logout() {
    console.log("Logging out");

    sessionStorage.removeItem(this.SS_JWT_TOKEN);
  }

  async getUser() {
    console.log("Getting user info...");

    const token = sessionStorage.getItem(this.SS_JWT_TOKEN);
    var decodedToken = decode(token);

    return (
      (decodedToken && decodedToken.email) || (decodedToken && decodedToken.sub)
    );
  }
}

export default ServiceAuthClient;
