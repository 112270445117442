// @ts-check

// React
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// core
import { ASUHeader } from "@asu-design-system/component-header/dist/asuHeader.es";
import desktopLogo from "../assets/img/arizona-state-university-logo-vertical.png";
import mobileLogo from "../assets/img/arizona-state-university-logo.png";

// authorization
import useServiceAuth from "hooks/useServiceAuth";

// style
import styled from "styled-components";

// Some customization, only if necessary, it is not mandatory
const HeaderSection = styled.div`
  header {
    position: sticky;

    // this align the "Create" button
    .content-container {
      font-size: 1rem;
      border: 0;
      margin: 0;
      height: fit-content;
      align-items: start;
      flex-direction: row;
    }

    a {
      cursor: pointer;
    }
  }
`;

const routerUrl = {
  HOME: "/",
  DATA: "/data",
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user, logout, redirectToServiceauth } =
    useServiceAuth();
  const ssoName = document.cookie
    .split("; ")
    ?.find((cookie) => cookie.startsWith("SSONAME"))
    ?.split("=")[1];

  return (
    <HeaderSection>
      <ASUHeader
        title="Canvas Enrollment System"
        logo={{
          alt: "logo",
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        loggedIn={isAuthenticated}
        userName={ssoName ? ssoName : user}
        onLoginClick={redirectToServiceauth}
        onLogoutClick={logout}
        navTree={[
          {
            onClick: () => navigate(routerUrl.HOME),
            text: "Home",
            selected: location.pathname === routerUrl.HOME ? true : false,
          },
          {
            onClick: () => navigate(routerUrl.DATA),
            text: "Data",
            selected: location.pathname === routerUrl.DATA ? true : false,
          },
        ]}
      />
    </HeaderSection>
  );
};

export default Header;
