import React, { useEffect, useState } from "react";
import axios from "axios";
import useServiceAuth from "hooks/useServiceAuth";
import useDataTable from "hooks/useDataTable";

const DataTable = () => {
  const [APIData, setAPIData] = useState([]);
  const { getAccessToken } = useServiceAuth();
  const { changed, finishChange } = useDataTable();

  useEffect(() => {
    async function requestAPI() {
      const token = await getAccessToken();
      const api = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_ENV}/items`;
      // const api = `https://cfqwyu1v1g.execute-api.us-west-2.amazonaws.com/qa/items`;
      const headers = {
        headers: { Authorization: `Bearer ${token}` },
      };

      console.log(api);

      // Send GET request to API and populate the page with data from the DynamoDB table
      await axios
        .get(api, headers)
        .then((response) => {
          console.log(response.data);
          setAPIData(response.data["Items"]);
        })
        // Catch any errors that may occur with the API
        .catch((error) => {
          console.log(error);
        });
    }
    console.log("Requesting API");
    requestAPI();

    if (changed) {
      console.log("Data has changed. Re-rendering...");
      finishChange();
    }
  }, [getAccessToken, changed, finishChange]);

  // Send an API request to the AWS Lambda function using API Gateway

  return (
    <div className="container">
      <h2>All items</h2>
      <table className="table">
        <thead>
          <tr className="row">
            <th className="col-4" scope="col">
              ID
            </th>
            <th className="col-4" scope="col">
              Name
            </th>
            <th className="col-4" scope="col">
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          {APIData.map((item, i) => (
            <tr key={i} className="row">
              <th className="col-4" scope="row">
                {item["id"]}
              </th>
              <td className="col-4">{item["name"]}</td>
              <td className="col-4">{item["price"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
